import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults } from "../../admin.state";
import { CertificationsService } from '../certifications.service';
import { AddCertifications, LoadAllCertificationsAsOption, LoadCertifications, RemoveCertifications, UpdateCertifications } from './certifications.actions';
import { CertificationsStateModel } from "./certifications.model";

@Injectable()
@State<CertificationsStateModel>({
  name: "certifications",
  defaults: {
    ...AdminPagesStateDefaults,
    itemsAsOptions: []
  }
})
export class CertificationsState {
  constructor(
    private certificationsService: CertificationsService
  ) {}

  @Selector() static certifications(state: CertificationsStateModel) { return state.items; }
  @Selector() static certificationsAsOptions(state: CertificationsStateModel) { return state.itemsAsOptions; }
  @Selector() static totalCount(state: CertificationsStateModel) { return state.totalCount; }
  @Selector() static loading(state: CertificationsStateModel) { return state.loading; }
  @Selector() static saving(state: CertificationsStateModel) { return state.saving; }

  @Action(LoadCertifications)
  load(ctx: StateContext<CertificationsStateModel>, { skipCount, maxResultsCount }) {
    ctx.patchState({ items: [], loading: true });
    return this.certificationsService.getCertifications(skipCount, maxResultsCount).subscribe(
      (response) => {
        ctx.patchState({
          items: response.items,
          totalCount: response.totalCount,
          loaded: true,
          loading: false
        });
      }
    );
  }

  @Action(LoadAllCertificationsAsOption)
  loadAllAsOption(ctx: StateContext<CertificationsStateModel>) {
    return this.certificationsService.getCertifications(0, 500).subscribe(
      (response) => {
        ctx.patchState({
          itemsAsOptions: response.items.map((c) => {
            return { text: c.title, value: c.id };
          })
        })
      }
    );
  }

  @Action(AddCertifications)
  addCertifications(ctx: StateContext<CertificationsStateModel>, action: AddCertifications) {
    ctx.patchState({ saving: true });

    return this.certificationsService.create(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          const state = ctx.getState();
          ctx.patchState({
            items: [response, ...state.items],
            totalCount: state.totalCount + 1,
            saving: false
          });
        },
        (error) => {
          action.callbackError();
          ctx.patchState({saving: false });
        }
      )
    );
  }

  @Action(UpdateCertifications)
  updateCertifications(ctx: StateContext<CertificationsStateModel>, action: UpdateCertifications) {
    ctx.patchState({ saving: true });

    return this.certificationsService.update(action.payload).subscribe(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: getUpdatedItems(response, state.items),
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(RemoveCertifications)
  removeCertifications(ctx: StateContext<CertificationsStateModel>, action: RemoveCertifications) {
    return this.certificationsService.remove(action.certificationId).subscribe(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: state.items.filter(item => { return item.id !== action.certificationId; }),
          totalCount: state.totalCount - 1
        });
      },
      (error) => {
        action.callbackError();
      }
    );
  }
}
