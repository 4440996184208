import { AsyncPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { addAnimation } from 'src/app/core/animations';
import { NotificationService } from 'src/app/core/services/notification.service';
import { LoadEmployers } from 'src/app/features/admin/employers/state/employers.actions';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DialogFooterComponent } from 'src/app/shared/components/dialog-footer/dialog-footer.component';
import { DropdownComponent } from 'src/app/shared/components/dropdown/dropdown.component';
import { MultiSelectDropdownChipsComponent } from 'src/app/shared/components/multi-select-dropdown-chips/multi-select-dropdown-chips.component';
import { TextInputComponent } from 'src/app/shared/components/text-input/text-input.component';
import { YesOrNoOptions } from 'src/app/shared/consts/yes-or-not-options.const';
import { SelectOption } from 'src/app/shared/interfaces/select-option.interface';
import { PrefillDataState } from 'src/app/shared/states/prefill-data/prefill-data.state';
import { Certification } from '../../models/certification.model';
import { AddCertifications, LoadAllCertificationsAsOption, UpdateCertifications } from '../../state/certifications.actions';
;

@Component({
  selector: 'app-certification-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AsyncPipe,
    TextInputComponent,
    DropdownComponent,
    MultiSelectDropdownChipsComponent,
    DialogFooterComponent
  ],
  templateUrl: './certification-form.component.html',
  animations: [addAnimation]
})
export class CertificationFormComponent implements OnInit, OnDestroy {

  title: string;
  
  occCodes: SelectOption[];
  preRequisites: SelectOption[];
  employers: SelectOption[];
  yesOrNoOptions: SelectOption[];
  
  certificationForm: UntypedFormGroup;
  selectedCodeIds: number[];
  selectedPreRequisiteIds: string[];
  selectedEmployerIds: number[];
  
  private unsubscribe$: Subject<void>;
  @Select(state => state.certifications.saving) isSaving$: Observable<boolean>;
  
  constructor(
    private dialogRef: MatDialogRef<CertificationFormComponent>,
    private dialog: MatDialog,
    private store: Store,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      certification: Certification;
    }
  ) { 
    this.title = '';
    this.yesOrNoOptions = YesOrNoOptions;
    this.dialogRef.disableClose = true;
    this.unsubscribe$ = new Subject<void>();
    this.subscribeBackdropDialog();
  }

  private subscribeBackdropDialog(): void {
    this.dialogRef.backdropClick().subscribe(result => {
      this.dialog.open(ConfirmDialogComponent, {
        width: "400px",
        data: {
          message: `Are you sure you want to leave?`,
          onConfirm: () => {
            this.dialog.closeAll();
            this.dialogRef.close();
          }
        }
      });
    });
  }

  ngOnInit(): void {
    this.setModalTitle();
    this.createForm();
    this.store.dispatch([
      new LoadEmployers(),
      new LoadAllCertificationsAsOption()
    ]);
  }

  private setModalTitle(): void {
    this.title = this.data?.certification ? 'Edit Certification' : 'Create New Certification';
  }

  private createForm(): void {
    this.certificationForm = new UntypedFormGroup({
      "title": new UntypedFormControl(
        this.data?.certification?.title || '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(200)]),
      "hasExpiration": new UntypedFormControl(
        this.data?.certification?.hasExpiration,
        [Validators.required]),
      "expiresInMonths": new UntypedFormControl(
        this.data?.certification?.expiresInMonths || null,
        this.data?.certification?.hasExpiration ? [Validators.required] : [])
    });
    this.subscribeHasExpiration();
    this.setOccCodeOptions();
    this.setPreRequisiteOptions();
    this.setEmployerOptions();
  }

  private subscribeHasExpiration(): void {
    this.certificationForm.get('hasExpiration').valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (value) => {
        if (value) {
          this.certificationForm.get('expiresInMonths').setValidators([Validators.required]);
        } else {
          this.certificationForm.get('expiresInMonths').setValue(null);
          this.certificationForm.get('expiresInMonths').setValidators([]);
        }
      }
    );
  }

  private setOccCodeOptions(): void {
    const occCodes: Array<SelectOption> = this.store.selectSnapshot(state => state.prefillData.occCodes);
    this.occCodes = occCodes.map((c) => {
      return { ...c, hidden: false }
    });
    this.selectedCodeIds = this.data?.certification?.occCodesIds || [];
  }
  
  private setPreRequisiteOptions(): void {
    this.store.select(state => state.certifications.itemsAsOptions).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (response) => {
        this.preRequisites = response.map((c) => {
          return { ...c, hidden: false }
        });
        if (this.data?.certification) {
          this.preRequisites = this.preRequisites.filter((r) => {
            return r.value !== this.data?.certification.id;
          });
        }
        this.selectedPreRequisiteIds = this.data.certification?.preRequisitesIds || [];
      }
    );
  }

  private setEmployerOptions(): void {
    this.store.select(PrefillDataState.allEmployers).pipe(
      takeUntil(this.unsubscribe$) 
    ).subscribe(
      (response) => {
        this.employers = response.map((c) => {
          return { ...c, hidden: false }
        });
        this.selectedEmployerIds = this.data.certification?.employersIds || [];
      } 
    )
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.disableClose = true;
    const payload: Certification = {
      ...this.certificationForm.value, 
      occCodesIds: this.selectedCodeIds,
      preRequisitesIds: this.selectedPreRequisiteIds,
      employersIds: this.selectedEmployerIds
    }
    if (this.data.certification) {
      payload.id = this.data?.certification?.id;
      this.store.dispatch(
        new UpdateCertifications(
          payload,
          () => {
            this.notificationService.showSuccess("The certification was successfully updated");
            this.dialogRef.close();
          },
          () => {
            this.notificationService.showError("There was a problem updating the certification. Please try again.");
          }
        )
      );
    } else {
      this.store.dispatch(
        new AddCertifications(
          payload,
          () => {
            this.notificationService.showSuccess("The certification was successfully created");
            this.dialogRef.close();
          },
          () => {
            this.notificationService.showError("There was a problem creating the certification. Please try again.");
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}