<h3 class="font-extrabold mb-6">{{ title }}</h3>

<form (ngSubmit)="save()" [formGroup]="certificationForm" class="overflow-x-hidden">
	<fieldset [disabled]="isSaving$ | async">
		<div class="grid grid-cols-6">
			<div class="mr-4 mb-4 col-span-2">
				<app-text-input
					[placeholder]="'Type Title'"
					[minLength]="5"
					[label]="'Title'"
					[required]="true"
					formControlName="title"
					class="block" />
			</div>
			<div class="mr-4 col-span-2">
				<app-dropdown 
					[placeholder]="'Select Has Expiration'"
					[required]="true"
					[label]="'Has Expiration'"
					[fullWidth]="true"
					[search]="true"
					[options]="yesOrNoOptions"
					formControlName="hasExpiration"
					class="block" />
			</div>
			<div class="col-span-2">
				<app-text-input
					[placeholder]="'Expires in Months'"
					type="number"
					[required]="certificationForm.get('hasExpiration').value ? true : null"
					[disabled]="!certificationForm.get('hasExpiration').value"
					[label]="'Expires in Months'"
					formControlName="expiresInMonths"
					class="block" />
			</div>
		</div>
		<div class="grid grid-cols-6">
			<div class="col-span-2 mr-4">
				<app-multi-select-dropdown-chips
					[placeholder]="'Select Codes'"
					[label]="'Codes'"
					[options]="occCodes"
					[selectedOptionIds]="selectedCodeIds"
					[enableUpdateAll]="true"
					(onChangeSelected)="selectedCodeIds = $event" />
			</div>
			<div class="col-span-2 mr-4">
				<app-multi-select-dropdown-chips
					[placeholder]="'Select Certifications'"
					[label]="'Pre Requisites'"
					[options]="preRequisites"
					[selectedOptionIds]="selectedPreRequisiteIds"
					[enableUpdateAll]="true"
					(onChangeSelected)="selectedPreRequisiteIds = $event" />
			</div>
			<div class="col-span-2">
				<app-multi-select-dropdown-chips
					[placeholder]="'Select Employers'"
					[label]="'Employers'"
					[options]="employers"
					[selectedOptionIds]="selectedEmployerIds"
					[enableUpdateAll]="true"
					(onChangeSelected)="selectedEmployerIds = $event" />
			</div>
		</div>
		<app-dialog-footer
			[isSaving]="isSaving$ | async"
			(cancelAction)="closeDialog()"
			[submitActionName]="data.certification ? 'Save' : 'Add'"
			[valid]="certificationForm.valid" />
	</fieldset>
</form>
