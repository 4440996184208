import { Certification } from '../models/certification.model';

export class LoadCertifications {
  public static readonly type = "[Certifications] load";
  constructor(
    public skipCount: number,
    public maxResultsCount: number
  ) {}
}

export class LoadAllCertificationsAsOption {
  public static readonly type = "[Certifications] load all as option";
}

export class AddCertifications {
  public static readonly type = "[Certifications] add";
  constructor(
    public payload: Certification,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdateCertifications {
  public static readonly type = "[Certifications] update";
  constructor(
    public payload: Certification,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemoveCertifications {
  public static readonly type = "[Certifications] remove";
  constructor(
    public certificationId: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}