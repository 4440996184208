import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { RemoveCertifications } from './state/certifications.actions';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { API_URL } from 'src/environments/settings';
import { Certification } from './models/certification.model';
import { CertificationFormComponent } from './components/certification-form/certification-form.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { NotificationService } from 'src/app/core/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class CertificationsService {

  private apiUrl: string;
  
  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private store: Store,
    private dialog: MatDialog,
    private notificationService: NotificationService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getCertifications(skipCount: number, maxResultsCount: number) {
    return this.http.get<any>(`${this.apiUrl}/app/certifications?MaxResultCount=${maxResultsCount}&SkipCount=${skipCount}`);
  }

  getCertificationById(id: string) {
    return this.http.get<any>(`${this.apiUrl}/app/certifications/${id}`);
  }

  update(certification: Certification) {
    return this.http.put<any>(`${this.apiUrl}/app/certifications/${certification.id}`, certification);
  }

  create(certification: Certification) {
    return this.http.post<any>(`${this.apiUrl}/app/certifications`, certification);
  }

  remove(certificationId: string) {    
    return this.http.delete(`${this.apiUrl}/app/certifications/${certificationId}`);
  }

  editCertification(certificationId: string): void {
    this.getCertificationById(certificationId).toPromise().then(
      (response: Certification) => {
        this.openCertificationForm(response);
      }
    );
  }

  openCertificationForm(certification?: Certification): void {
    this.dialog.open(CertificationFormComponent, {
      width: "850px",
      data: {
        certification: certification
      }
    });
  }

  confirmDeleteCertification(certification: Certification): void {
    this.dialog.open(ConfirmDialogComponent, {
      width: "600px",
      data: {
        message: `You are about to delete ${certification.title}. Are you sure?`,
        loadingText: "Deleting...",
        onConfirm: () => {
          this.store.dispatch(
            new RemoveCertifications(
              certification.id,
              () => {
                this.dialog.closeAll();
                this.notificationService.showSuccess("The certification was successfully deleted");
              },
              () => {
                this.notificationService.showError("There was a problem deleting the certification. Please try again.");
              }
            )
          )
        }
      }
    });
  }
}
